import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import Cross from '../icons/Cross';
import useBreakpoint from './restyled/utilities/useBreakpoint';
import Icon from './restyled/Icon';
import classNames from 'classnames';

interface BannerProps {
    title: string,
    backgroundColour: string,
    link?: string,
    linkText?: string;
}

const MARQUEE_SPEED = 0.2;

const EditableBanner = ({
    link, linkText, title, backgroundColour
}: BannerProps) => {
    const [close, setClose] = useState(false);
    const [centered, setCentered] = useState(true);
    const marqueeWrapperRef = useRef<HTMLDivElement>(null);
    const marqueeContentRef = useRef<HTMLHeadingElement>(null);
    
    useEffect(() => {
        document.body.style.setProperty('--banner-height', close ? '0px' : '32px');
    }, [close])

    useLayoutEffect(() => {
        if(close) {
            return ;
        }

        const marqueeWrapperElement = marqueeWrapperRef.current;
        const marqueeContentElement = marqueeContentRef.current;

        const handleWindowResize = () => {
            setCentered(marqueeWrapperElement.offsetWidth > marqueeContentElement.offsetWidth)
        }

        window.addEventListener('resize', handleWindowResize);

        handleWindowResize();

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        }
    }, [close])

    useEffect(() => {
        const marqueeWrapperElement = marqueeWrapperRef.current;
        const marqueeContentElement = marqueeContentRef.current;

        if(!marqueeWrapperElement || !marqueeContentElement || centered) {
            return;
        }

        const clone = marqueeWrapperElement.innerHTML;

        let distance = 0;
        let repaint = true;

        marqueeWrapperElement.insertAdjacentHTML('beforeend', clone);
        marqueeWrapperElement.insertAdjacentHTML('beforeend', clone);

        const step = () => {
            marqueeContentElement.style.marginLeft = `-${distance}px`;

            if (distance > marqueeContentElement.clientWidth) {
                distance = 0;
            }

            distance = distance + MARQUEE_SPEED;

            if(repaint) {
                requestAnimationFrame(step)
            } else {
                marqueeContentElement.nextElementSibling.remove();
                marqueeContentElement.nextElementSibling.remove();
                marqueeContentElement.style.marginLeft = '0px';
            }
        }

        requestAnimationFrame(step);

        return () => {
            repaint = false;
        }
    }, [centered])

    let content = <>{title}</>;

    if (link && linkText) {
        content = (
            <a className='c-editableBanner__link' href={link}>
                {title}
                <span className='c-editableBanner__underlinedText'>{linkText}</span>
            </a>
        )
    }

    return !close && (
        <div className={`c-editableBanner c-editableBanner--${backgroundColour}`}>
            <div className="c-editableBanner__content">
                <div className={classNames("c-editableBanner__marqueeWrapper", centered && 'c-editableBanner__marqueeWrapper--centered')} ref={marqueeWrapperRef}>
                    <div className='c-editableBanner__marqueeContent' ref={marqueeContentRef}>
                        <h1 className="c-editableBanner__title">
                            {content}
                        </h1>
                    </div>
                </div>
                <button className="c-editableBanner__button" onClick={() => setClose(true)} type="button">
                    <Icon symbol='close' />
                </button>
            </div>
        </div>
    )
};

export default EditableBanner;
